/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 73번째 로그에서는 원격녹음, 원격근무 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/64/"
  }, "stdout_064.log: 원격 녹음, 우아한형제들 인수, 루비 2.7 릴리스, JapanTaxi 등 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.audacityteam.org/"
  }, "Audacity ® | Free, open source, cross-platform audio software for multi-track recording and editing.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.donga.com/news/article/all/20200226/99876801/1"
  }, "JP모건 “韓코로나 내달 20일 정점… 감염 최대 1만명까지 늘어날수도”")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/savings-plan-update-save-up-to-17-on-your-lambda-workloads/"
  }, "Savings Plan 업데이트- AWS Lambda 사용 비용 최대 17% 절감 | Amazon Web Services 한국 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.mozilla.org/blog/2020/02/25/firefox-continues-push-to-bring-dns-over-https-by-default-for-us-users/"
  }, "Firefox continues push to bring DNS over HTTPS by default for US users - The Mozilla Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/72/"
  }, "스탠다드아웃_072.log: 데이터 분석, CCPA, 팩트풀니스(한스 로슬링) w/ 꼬젯 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.gitlab.com/releases/2020/02/22/gitlab-12-8-released/"
  }, "GitLab 12.8 released with Log Explorer, NuGet, and Compliance | GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.gitlab.com/stages-devops-lifecycle/"
  }, "The DevOps Lifecycle with GitLab | GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/features/actions"
  }, "Features • GitHub Actions")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/changelog/2019-11-05-github-actions-self-hosted-runners/"
  }, "GitHub Actions: Self-hosted runners - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/managed-microsoft-ad"
  }, "Managed Microsoft AD  |  Google Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/directoryservice/"
  }, "AWS Directory Service | Amazon Web Services (AWS)")), "\n"), "\n", React.createElement(_components.h2, null, "재택근무"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ciokorea.com/t/527/sns/129563"
  }, "구글, '행아웃' 지원 종료 시한 내년 6월로 연기 - CIO Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://meet.google.com/_meet?utm_campaign=DonanimHaber&utm_medium=referral&authuser=1&utm_source=DonanimHaber"
  }, "Meet")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://duo.google.com/about/"
  }, "Google Duo - The simple video calling app.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.makepagecall.com/"
  }, "Make PageCall")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/66/"
  }, "스탠다드아웃_066.log: 로블록스, 기계는 어떻게 생각하는가, 실리콘밸리 개발자 등 w/ 이수겸 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://biz.chosun.com/site/data/html_dir/2015/06/23/2015062304520.html"
  }, "\"재택근무하니… 부장님 대신 엄마가 자꾸 부르네요\" - Chosunbiz > 피플")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.slideshare.net/KellyYun/smartstudy-fullremote201506-49674381"
  }, "스마트스터디 - 재택근무 잘 하고 있어요")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
